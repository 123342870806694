/// <reference path="../../../typings/libraries.d.ts"/>

function updateHeaderProfileImage(cacheIdentifier: string = null) {
    if (!Alkami.Utils.StorageHelper.hasSessionStorage()) {
        // if we can't access session storage due to being in private mode
        return;
    }
    const cacheKey = 'USER_PORTRAIT_CACHE_IDENTIFIER';

    if (cacheIdentifier === null) {
        cacheIdentifier = window.sessionStorage.getItem(cacheKey);
        if (!cacheIdentifier) {
            // updated cacheidentifier does not exist.
            // there is no need to update header profile image.
            return;
        }
    } else {
        window.sessionStorage.setItem(cacheKey, cacheIdentifier);
    }

    // Find our Profile Image
    const profileImage: HTMLImageElement | null = document.querySelector('#profile_pop .iris-avatar__image');
    if (profileImage === null) {
        // The image element doesn't exist. We are probably on the Authentication page
        return;
    }

    const url = new URL(profileImage.src);
    const params = new URLSearchParams(url.search);
    params.set('CacheIdentifier', cacheIdentifier);
    url.search = params.toString();
    profileImage.src = url.toString();
}
//
// Needs to run on load every time to get past the caching of the header profile image.
// Outside of Alkami.Dom.onDocumentReady() to prevent a brief (~250ms) flash of original profile image.
//
updateHeaderProfileImage();

Alkami.Dom.onDocumentReady(() => {
    // Functionality for the profile_nav dropdown 
    // =============================================================================
    const profileNavElement = document.getElementById('profile_nav');

    if (profileNavElement) {
        const dropdownOptionsElement = document.querySelector('#profile_nav .dropdown-options');
        const dropdownToggleElement = document.querySelector('#profile_nav .dropdown-toggle');

        // Hide the dropdown on first load
        if (dropdownOptionsElement) {
            Alkami.Dom.hideElement(dropdownOptionsElement);
        }

        // Set up the click handlers for the toggle element
        if (dropdownToggleElement) {
            dropdownToggleElement.addEventListener('click', (event) => {
                event.stopPropagation();

                Alkami.Dom.fadeToggle(dropdownOptionsElement, 200, () => {
                    if (dropdownToggleElement.getAttribute('aria-expanded') === 'false') {
                        dropdownToggleElement.setAttribute('aria-expanded', 'true');
                        dropdownOptionsElement.setAttribute('aria-hidden', 'false');
                    } else {
                        dropdownToggleElement.setAttribute('aria-expanded', 'false');
                        dropdownOptionsElement.setAttribute('aria-hidden', 'true');
                    }
                });

                dropdownToggleElement.classList.toggle('active');
            });
        }

        // If the document is clicked outside of the profile, close it
        document.addEventListener('click', (event) => {
            if (Alkami.Dom.isVisible(dropdownOptionsElement)) {
                Alkami.Dom.fadeOut(dropdownOptionsElement, 200, () => {
                    dropdownToggleElement.setAttribute('aria-expanded', 'false');
                    dropdownOptionsElement.setAttribute('aria-hidden', 'true');
                });

                dropdownToggleElement.classList.remove('active');
            }
        });
    }


    // Functionality for the profile_nav_toggle dropdown 
    // =============================================================================
    const profileNavToggleElement = document.getElementById('profile_nav_toggle');

    if (profileNavToggleElement) {
        const showProfileMenuElement = document.getElementById('show_profile_menu');

        profileNavToggleElement.addEventListener('click', (event) => {
            event.stopPropagation();

            if (showProfileMenuElement) {
                Alkami.Dom.fadeToggle(showProfileMenuElement, 100, () => {
                    if (profileNavToggleElement.getAttribute('aria-expanded') === 'false') {
                        profileNavToggleElement.setAttribute('aria-expanded', 'true');
                        showProfileMenuElement.setAttribute('aria-hidden', 'false');
                    } else {
                        profileNavToggleElement.setAttribute('aria-expanded', 'false');
                        showProfileMenuElement.setAttribute('aria-hidden', 'true');
                    }
                });
            }
        });

        document.body.addEventListener('click', (event) => {
            if ((event.target as HTMLElement).id != 'profile_nav_toggle') {
                profileNavToggleElement.setAttribute('aria-expanded', 'false');

                if (showProfileMenuElement) {
                    showProfileMenuElement.setAttribute('aria-hidden', 'true');
                    Alkami.Dom.fadeOut(showProfileMenuElement, 100);
                }
            }
        }, false);
    }


    // Functionality for the ribbon_profile_nav 
    // =============================================================================
    const ribbonProfileNav = document.getElementById('ribbon_profile_nav');

    if (ribbonProfileNav) {
        const ribbonShowProfileMenuElement = document.getElementById('ribbon_show_profile_menu');
        const ribbonProfileNavToggleElement = document.getElementById('ribbon_profile_nav_toggle');
        const profileNavDropdownOptionsElement = document.querySelector('#profile_nav .dropdown-options');
        const profileNavDropdownToggleElement = document.querySelector('#profile_nav .dropdown-toggle');
        const chevronElement = document.querySelector('#ribbon_profile_nav .iris-chevron');

        // Hide the options element on page load.
        if (ribbonShowProfileMenuElement) {
            Alkami.Dom.hideElement(ribbonShowProfileMenuElement);
        }

        if (ribbonProfileNavToggleElement) {
            ribbonProfileNavToggleElement.addEventListener('click', (event) => {
                event.stopPropagation();

                Alkami.Dom.fadeToggle(ribbonShowProfileMenuElement, 200, () => {
                    if (ribbonProfileNavToggleElement.getAttribute('aria-expanded') === 'false') {
                        ribbonProfileNavToggleElement.setAttribute('aria-expanded', 'true');
                        ribbonShowProfileMenuElement.setAttribute('aria-hidden', 'false');
                    } else {
                        ribbonProfileNavToggleElement.setAttribute('aria-expanded', 'false');
                        ribbonShowProfileMenuElement.setAttribute('aria-hidden', 'true');
                    }
                });

                ribbonProfileNavToggleElement.classList.toggle('active');
                chevronElement.classList.toggle('iris-chevron--up');
            });

            document.addEventListener('click', (event) => {
                if ((event.target as HTMLElement).id !== 'ribbon_profile_nav_toggle') {
                    Alkami.Dom.fadeOut(ribbonShowProfileMenuElement, 100, () => {
                        ribbonShowProfileMenuElement.setAttribute('aria-hidden', 'true');
                        ribbonProfileNavToggleElement.setAttribute('aria-expanded', 'false');
                    });

                    ribbonProfileNavToggleElement.classList.remove('active');
                    chevronElement.classList.remove('iris-chevron--up');
                }
            }, false);
        }

        // Finding Height of Header to set scroll to produce actions
        if (document.querySelector('.ribbon-user')) {
            const container = document.querySelector('.container-right') as HTMLElement;
            container.style.display = 'flex';

            const headerProfile = document.getElementById('profile_nav') as HTMLElement;
            const headerProfileHeight = headerProfile.clientHeight;
            const headerProfileImage = headerProfile.querySelector('img').src;

            const ribbonProfileImage = document.getElementById('ribbon_profile_image') as HTMLImageElement;
            ribbonProfileImage.src = headerProfileImage;

            const header = document.getElementById('header');

            // If a user is already scrolled down on the page and reloads, bounding rect will return a negative number.
            // Add the scrollY to account for this use case.
            const headerTop = header.getBoundingClientRect().top + window.scrollY;
            const headerBottom = headerTop + headerProfileHeight - parseInt(headerProfile.style.marginTop || '0');

            // Grabbing the individual divs that we want to move to get translation
            const profile = document.querySelector('.ribbon-user') as HTMLElement;
            profile.style.opacity = '0';
            container.setAttribute('style', 'transform: translateX(' + (profile.clientWidth + 10) + 'px); display: flex;');

            setTimeout(function () {
                container.style.transition = '0.75s';
                container.style.opacity = '1';
            }, 750);

            const pageScrolled = function () {
                const ribbonShowProfileMenuElement = document.getElementById('ribbon_show_profile_menu');
                container.style.transition = '0.75s';

                // various scrolltops because browsers like to be different
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

                if (scrollTop >= headerBottom) {
                    container.style.cssText = 'display: flex; transition: 0.75s; opacity: 1;';
                    profile.style.opacity = '1';

                    // If the standard profile dropdown is visible, make sure to close it when we scroll down.
                    if (Alkami.Dom.isVisible(profileNavDropdownOptionsElement)) {

                        // Need to make sure that if the profile dropdown is open we close it on scroll
                        Alkami.Dom.fadeOut(profileNavDropdownOptionsElement, 200, () => {
                            // Make sure we change the aria labels back
                            profileNavDropdownToggleElement.setAttribute('aria-expanded', 'false');
                            profileNavDropdownOptionsElement.setAttribute('aria-hidden', 'true');
                        });

                        profileNavDropdownToggleElement.classList.remove('active');
                    }
                } else {
                    container.style.cssText = `transform: translateX(${profile.clientWidth + 10}px); display: flex; transition: 0.75s; opacity: 1;`;
                    profile.style.opacity = '0';

                    if (Alkami.Dom.isVisible(ribbonShowProfileMenuElement)) {
                        Alkami.Dom.fadeOut(ribbonShowProfileMenuElement, 100, () => {
                            ribbonProfileNavToggleElement.setAttribute('aria-expanded', 'false');
                            ribbonShowProfileMenuElement.setAttribute('aria-hidden', 'true');
                        });

                        ribbonProfileNavToggleElement.classList.remove('active');
                        chevronElement.classList.remove('iris-chevron--up');
                    }

                }
            };

            window.addEventListener('scroll', pageScrolled);

            // When the page loads if the window is already scrolled call the scroll handler
            if (window.scrollY > 0) {
                pageScrolled();
            }
        };
    }


    // Functionality for the profile_lite element
    // =============================================================================
    const profileLiteUserNameElement = document.querySelector('#profile_lite .user') as HTMLElement;

    if (profileLiteUserNameElement) {
        const string = profileLiteUserNameElement.innerText;

        if (string.length > 10) {
            const substring = string.substring(10, string.length);
            profileLiteUserNameElement.innerText = string.replace(substring, '...')
        }
    }
    // End document ready
});
